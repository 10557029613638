<template>
    <div class="U_title">
        <div class="U_title_content">Your Location：{{this.Target.title}}</div>
        <!-- <div class="describe" @click="show()"  v-if="Pattern!='测试模式'&&Show">
            <img src="@/assets/shuoming.png" alt="">
            <span>Directions</span>
        </div> -->
    </div>
</template>

<script>
    export default {
        props:['Target','RightLeft','UpLayerData'],
        data(){
            return  {
                Show:false,
            }
        },
        created() {
            let path = this.$route.path;
            if(path.includes('describe')){
                this.Show = false;
            }else{
                this.Show = true;
            }
        },
        watch: {
            Target: {
                handler(n, o) {
                    this.$store.commit('setitem',{'name':'targetData','item':n})
                },
                deep: true
            }
        },
        methods: {
            show(){
                this.$emit('show');
            }
        },
    }
</script>

<style scoped>

</style>