<template>
    <div class="search-backdrop">
        <div class="search">
            <el-container class="search0">
                <!-- 顶栏 -->
                <el-header class="search-header" height="44px">
                    <h3 class="words-title">知识点搜索</h3>
                    <div class="left" v-if="!isShowHotSearch">
                        <div class="searchInput">
                            <el-input type="text" v-model="input" auto-complete="off" class="searchText"
                                      prefix-icon="iconfont el-icon-search"/>
                        </div>
                        <span class="searchButton" @click="search">Search</span>
                    </div>
                    <i class="el-icon-close right" @click="closeSelf"></i>
                </el-header>
                <!-- 热搜 -->
                <el-container v-if="isShowHotSearch">
                    <HotSearch ref="HotSearch" userId="ijunmuijnm" @handleSearch="handleSearch"></HotSearch>
                </el-container>
                <!-- 嵌套容器 -->
                <el-container v-else>
                    <!-- 侧边导航菜单 -->
                    <el-aside width="220px" class="side">
                        <div v-for='(item, index) in asideData' :key='index' class="leftcontent" :class="{leftcontentActive: index == currentMenuIndex }" @click='query(item,index)'>
                            <div >{{item.key}}({{item.num}})</div>
                        </div>
                    </el-aside>
                    <!-- 内容 -->
                    <el-main class="main1">
                        <div class="content">
                            <el-collapse accordion v-model="collapse">
                                <div v-for='(item,index) in itemData.slice((currentPage-1)*pageSize,(currentPage*pageSize))' :key='index' class="midcontent">

                                    <el-collapse-item>
                                        <template slot="title">
                                            <div class="title-content" >
                                                <div class="title-content-item" >
                                                    <div class="hintContent">
                                                        <AudioPlayer ref="stemAudioPlayer" 
                                                            v-if="item.audioUrl != null"
                                                            :theUrl="item.audioUrl"
                                                            @audioEndedOver="PlayerComplete"
                                                            :config="AudioPlayerConfig"/>
                                                        <span  >{{ item.hintContent}}</span>
                                                    </div>
                                                    <div class="soundmark">
                                                        <span>{{item.soundmark}}</span>
                                                    </div>
                                                    <div class="underlineLetter">
                                                        {{item.underlineLetter}}
                                                    </div>                                                
                                                </div>
                                            </div>
                                        </template>

                                        <div class="collapse-item-content">
                                            <span style="color: #2d8cf0" @click="gotoUrl(item)">{{item.sectionName}}</span>
                                        </div>
                                    </el-collapse-item>
                                </div>
                            </el-collapse>
                        </div>
                        <el-footer class="main-footer" height="38px">
                            <p>共 <span class="num">{{itemData.length}}</span>条信息</p>
                            <el-pagination layout="prev, pager, next"
                                           :total="itemData.length"
                                           :page-size="pageSize"
                                           :current-page ="currentPage"
                                           @current-change="handleCurrentChange"
                                           class="page">
                            </el-pagination>
                        </el-footer>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<style lang="less" scoped>

    .wordsimg {
        width: 300px;
        height: 200px;
    }

    .search-backdrop {
        z-index: 600;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search {
        background-color: #fff;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        width: 1034px;
        height: 644px;

        .search0 {
            height: 100%;

            .el-container {
                height: 556px;

                .content {
                    height: 510px;
                    overflow-y: scroll;
                    background-color: #ffffff;
                }
            }
        }
    }

    .search-header {
        background-color: rgba(236, 237, 243, 0.39);
        border-bottom: 1px solid #eee;
        color: #313131;
        justify-content: space-between;
        padding: 15px;
        display: flex;
        height: 10px;
        align-items: center;
        .words-title {
            font-size: 16px;
            color: #333B4E;
            font-family: PingFang SC;
            font-weight: bold;          
        }
        .left {
            display: flex;
            align-items: center;
            height: 30px;
            .searchInput {
                width: 262px;
                i {
                    font-size: 12px;
                }
            }
            /deep/ .el-input__inner {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border: 0;
                height: 30px;
                width: 262px;
            }

            /deep/ .el-input__icon {
                line-height: normal;
            }

            .searchButton {
                width: 99px;
                height: 30px;
                background-color: #000;
                color: #fff;
                padding: 0px 10px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                cursor: pointer;
                font-size: 16px;
                text-align: center;
                line-height: 30px;

                &:hover {
                    background-color: #999999;
                }
            }
        }
        i {
            font-size: 20px;
            font-weight: bold;
        }
        /deep/ .el-icon-close {
            width: 20px;
            height: 20px;
        }
    }

    .main1 {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 1px;

        .main-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;

            .num {
                color: #00D2CB;
            }
        }

        .content {
            .midcontent {
                border-bottom: 1px solid #F0F0F0;
            }
            .title-content {
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: center;
                font-size: 16px;
                text-align: left;
                .title-content-item{
                    width: 763px;
                    display: flex;
                    flex: 1;
                }
                .hintContent{
                    flex: 0 0 150px;
                     white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 20px;
                }
                .soundmark{
                    flex: 0 0 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 20px;
                }
                .underlineLetter{
                    flex: 0 0 350px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .Favorites {
                    width: 15px;
                    height: 15px;
                }
                .chineseText {
                    display: inline-block;
                    margin-left: 20px;
                }
            }

            /deep/ .el-collapse-item__header {
                display: flex;
                justify-content: space-between;
                height: auto;
            }

            /deep/ .el-icon-arrow-right {
                margin-left: 20px;
            }

            /deep/ .el-collapse-item__wrap {
                background: #FBFBFB;
                overflow: auto;
                .collapse-item-content {
                    margin-top: 10px;
                    text-align: left;
                    margin-left: 29px;
                    width: 90%;
                    cursor: pointer;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .Favorites {
            .unActive {
                display: inline-block;
                width: 0.42rem;
                height: 0.42rem;
                background: url('../../assets/Favorites2.png') no-repeat;
                background-size: cover;

                &:hover {
                    background: url('../../assets/Favorites2.png') no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    .btn-close, .btn-confirm {
        border-radius: 8px;
        margin-left: 16px;
        width: 56px;
        height: 36px;
        border: none;
        cursor: pointer;
    }

    .btn-close {
        color: #313131;
        background-color: transparent;
    }

    .btn-confirm {
        color: #fff;
        background-color: #2d8cf0;
    }

    .side {
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        padding-left: 5px;

        .leftcontent {
            box-sizing: border-box;
            margin: 5px 0px;
            width: 100%;
            height: 28px;
            background-color: #eeeeee;
            padding-left: 3px;
            line-height: 28px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 18px;
            &:hover {
                background-color: #999999;
            }
        }
        .leftcontentActive{
            background-color: #999999;
        }

        overflow-y: scroll;
    }

    .el-footer {
        background-color: #ffffff;
        color: black;
        text-align: center;
        border: 2px solid #eeeeee;

        .page {

        }
    }

    .content {
        background-color: #909399;
        color: black;
        text-align: center;
    }

    .el-icon-close {
        cursor: pointer;
        padding: 3px 4px 3px 3px;
        width: 10px;
        height: 10px;
    }

    .el-icon-close:hover {
        background-color: #fff;

        border-radius: 50%;
    }

    .words2 {
        display: flex;
        align-items: center;
        font-size: 15px;
        height: 100px;
    }

    .content3 {
        display: flex;
        align-items: center;
        font-size: 15px;
        height: 100px;
        overflow-y: auto;
    }
</style>

<script>
    import HotSearch from "./hotSearch.vue"

    export default {
        name: 'search',
        props: {
            quesTempId:{
                type:String,
                default:""
            },
        },
        components: {HotSearch},
        data() {
            return {
                isShowHotSearch: true,
                favpic: require('../../assets/Favorites1.png'),
                data: null,
                form: {
                    words: ''
                },
                input: "",
                sideData: [],
                midData: [],
                pageData: [],
                AudioPlayerConfig: {
                    autoplay: false,
                    theSpeeds: [1],
                },
                allData:{},
                asideData:[],
                itemData:[],
                currentPage:0,
                pageSize:10,
                currentMenuIndex:0,

                currentMenuData:[],
                collapse:""
            }
        },
        methods: {
            Goto(item) {
                let list = this.menulist;
                console.log(list)
                let layer = item.ceng.split(',');
                layer = layer.map((el) => {
                    el = el.split('.');
                    return el;
                })
                if (item.allSectionModel == 3) { // 测试模式
                    let starttime = new Date();
                    starttime = window.formatDateTime(starttime);
                    sessionStorage.setItem('starttime', starttime);
                }

                var id = item.id
                console.log(id)
                let ceng = item.ceng
                console.log(item.ceng)

                let id2 = list[layer[0][1]].id
                console.log(id2)

                let pid = item.pid
                console.log(pid)

                sessionStorage.setItem('checkid', item.id);
                sessionStorage.setItem('targetceng', item.ceng);

                this.$store.commit('setitem', {'name': 'checkid', 'item': item.id})
                this.$store.commit('setitem', {'name': 'outerId', 'item': list[layer[0][1]].id});
                this.$store.commit('setitem', {'name': 'dataLayer', 'item': item.ceng})
                this.$router.push('/home/describe?pid=' + item.pid);
            },
            searchData(data, key){
                console.log('key',data, key);
                if(data.childList && data.childList.length > 0 ) {
                    for (let j=0; j < data.childList.length; j++) {
                        this.searchData(data.childList[j], key)
                    }
                }
                if(data.id === key){
                    this.$store.commit('setitem',{'name':'searchActive','item':false});
                    this.Goto(data)
                }
                // if(data.childList){
                //     for (let i = 0; i < data.childList.length; i++) {
                //         let val = data.childList[i]
                //         if (val.id === key) {
                //             console.log(val)
                //             this.Goto(val)
                //         }
                //     }
                //     this.searchData(data.childList, key);
                // }
            },
            gotoUrl(item) {
                let menulist = JSON.parse( sessionStorage.getItem("menulist") )
                for (let j=0; j < menulist.length; j++) {
                    this.searchData(menulist[j], item.sectionId)
                }
            },
            setitemData(arr){
                let data = []
                arr.map(el=>{
                    let title = JSON.parse(el.title)
                    title.map(el2=>{
                        data.push({...el2,...el})
                    })
                })
                return data
            },
            query(item,index) {
                this.collapse = ''
                this.itemData = this.allData[item.key]
                this.itemData = this.setitemData(this.itemData)
                this.currentMenuIndex = index
                this.currentPage = 1
                console.log(this.itemData);
            },
            handleSearch(searchValue) {
                this.isShowHotSearch = false
                this.input = searchValue
                this.search()
            },
            handleCurrentChange(val) {
                this.collapse = ''
                this.currentPage = val
            },
            handleCurrentChange2(data, val) {
                let size = this.pageSize
                let index1 = (val - 1) * size
                var arr1 = new Array();
                let num
                if (val === 1) {
                    if (size <= data.length) {
                        num = index1 + size
                    } else {
                        num = index1 + data.length
                    }
                    for (let i = index1; i < index1 + num; i++) {
                        arr1.push(data[i])
                    }
                } else {
                    let num2 = data.length - index1
                    if (num2 < size) {
                        for (let i = index1; i < index1 + num2; i++) {
                            arr1.push(data[i])
                        }
                    } else {
                        for (let i = index1; i < index1 + size; i++) {
                            arr1.push(data[i])
                        }
                    }
                }
                if (arr1 === undefined) {
                    this.pageData = null
                } else {
                    this.pageData = arr1
                }
            },
            getPic(url) {
                let url1 = this.$store.state.common.AudioUrlPublic + '/Images/' + url
                console.log(url1)
                return url1
            },
            closeSelf() {
               this.$refs.HotSearch ? this.$refs.HotSearch.state = "" : ""
                this.isShowHotSearch = true
                this.$store.commit('setitem', {'name': 'searchActive', 'item': false});
            },
            change(e) {
                this.$forceUpdate()
            },
            async search() {

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })

                let courceId = this.$parent.menulist[layer[0][1]].courceId
                console.log(courceId)

                let searchValue = this.input.trim()

                let data;
                    let trainClassId = this.$route.query.trainClassId
                    let targetceng = sessionStorage.getItem('targetceng').substr(2,1)
                    let menulist = JSON.parse(sessionStorage.getItem('menulist')) 
                    // let sectionId = menulist[targetceng].id
                    data = await this.AjaxApi.getSearch({courceId, searchValue, trainClassId, quesTempId:this.quesTempId,
                        //  sectionId
                        })
                    if(data.code == 200 && data.data){
                        let dataKeyArr = Object.keys(data.data)
                         this.allData = data.data
                         this.asideData = dataKeyArr.map(el =>{
                            let itemData = this.allData[el]
                            let num = this.setitemData(itemData).length
                            return {key:el,num}
                         })
                        this.itemData =  this.allData[dataKeyArr[0]]
                        this.itemData = this.setitemData(this.itemData)
                        this.currentMenuIndex = 0
                        this.handleCurrentChange(1)
                    }else{
                        this.$message.warning('未搜索到相关内容！');
                    }
                
            }
        }
    }
</script>
