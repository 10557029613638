/*
 * @Author: heyang 
 * @Description:  知识搜索 热搜小板块
 * @ModuleName:  
 * @Date: 2018-09-27 17:38:59 
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-05 14:58:29
 */

<template>
    <div class="hotSearch">
        <div class="searchBox">
            <el-autocomplete
                v-model="state"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect"
                :maxlength="20"
                show-word-limit
                >
                <i
                    class="el-icon-search el-input__icon"
                    slot="prefix"
                    >
                </i>
            </el-autocomplete>
            <p class="Search-btn" @click="handleSearch">Search</p>
        </div>
        <p class="hot">Hot</p>
        <div class="hotList">
            <div class="listItem" v-for="( item ,i ) in hotList" :key="i"  @click="handleSearchRow(item)">
                <el-row>
                    <el-col :span="21"> <span class="num" :style="{backgroundColor: i ==0 ? '#ED3D30' : (i==1?'#F17878':(i==2?'#F6A3A3':'#CFD4DB'))}">{{i+1}}</span> <span class="searchWord">{{item.searchWord}}</span> </el-col>
                    <!-- <el-col :span="8"> <span style="color:#696969"></span> </el-col> -->
                    <el-col :span="3"> <span style="color:#A3A3A3;font-size: 16px;">{{item.num}}次</span> </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hotList:[],
                restaurants: [],
                state: '',
                timeout:  null,
            };
        },
        props:{
            courceId:{
                type:String,
                default:""
            },
            userId:{
                type:String,
                default:""
            }
        },
        created(){
            this.queryListByCourceId()
        },
        methods: {
            handleSearch(){
                this.$emit('handleSearch',this.state.trim())
            },
            handleSearchRow(item){
                this.state = item.value
                this.handleSearch()
            },
            loadAll() {
                return [
                    { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
                    { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
                    { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
                    { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
                ];
            },
            querySearchAsync(queryString, cb) {
                console.log(queryString,'queryString');
                var restaurants = this.hotList;
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
                cb(results);

            },
            createStateFilter(queryString) {
                return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                console.log(item);
            },
            async queryListByCourceId(){
                let targetceng = sessionStorage.getItem('targetceng').substr(2,1)
                    let menulist = JSON.parse(sessionStorage.getItem('menulist')) 
                    // let sectionId = menulist[targetceng].id
                let data = {
                    courceId:this.$store.state.common.curriculum,
                    userId:sessionStorage.getItem('userId'),
                    // sectionId
                }
                let res = await this.AjaxApi.queryListByCourceId(data);
                if(res.code == 200 && res.data.length>0){
                    this.hotList = res.data.map(el=>{
                        el.value = el.searchWord
                        el.address = el.searchWord
                        return el
                    })
                }
                console.log(this.hotList,'pppp');

            }
        },
        mounted() {
            this.restaurants = this.loadAll();
        }
    }
</script>

<style lang="less" scoped>
.box{
    width: 1000px;
    height: 600px;
    background-color: rgb(233, 129, 129);
}
.hotSearch{
    margin: 76px auto 0;
    width: 66.3%;
    // height: calc(100% - 40px) ;
    
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .hot{
        font-size: 20px;
        margin-top: 30px;
    }
    .searchBox{
        display: inline-block;
        display: flex;
        .el-autocomplete{
            width: 86%;
        }
        .el-input{
            width: 100%;
        }
        /deep/.el-input__inner{
            border-radius: 5px 0px 0px 5px;
            flex: 1;
            height: 51px;
        }
        .Search-btn{
            display: flex;
            // justify-content: center;
            align-items: center;
            color:#fff;
            background-color: #000;
            height: 51px;
            padding: 0px 5px;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
            width: 14%;
            font-size: 19px;
            line-height: 51px;
            justify-content: center;
            &:hover{
                opacity: 0.5;
            }
        }
    }
    .hotList{
        .el-row{
            margin-top: 25px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover{
                background: linear-gradient(to right,  #FAFAFA 0%,#ffffff 100%);;
            }
        }
        .num{
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: #000;
            color: #fff;
            border-radius: 3px ;
            text-align: center;
            line-height: 30px;
            margin-right: 20px;
            font-size: 18px;
        }
        .searchWord{
            font-size: 19px;
        }
    }
}

</style>